@media print {
    @page { 
        size: auto;
        margin: 0mm;
    }

    .addthis_toolbox {
        display: none !important;
    }

    .post-8044,
    .post-8046,
    .post-8048 {

        h1 {
            text-align: center;
        }

        .storycontent {

            .matStyleCalculator-container,
            .tab_sub_title {
                display: none !important;
            }

            .fl-col {
                width: 100% !important;
                max-width: none !important;
            }

            .fl-module-content {

                .fl-module-content {
                    margin: 0 !important;
                }
            }
        }

        .fl-builder-content {

            & > .fl-row:first-child {
                display: none !important;
            }
        }

        .fl-button {
            display: none !important;
        }
    }

}