.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;

    &:last-child {
        margin-bottom: 0;
    }

    & > thead {

        & > tr {

            & > th {
                border-width: 1px;
                padding: 5px;
            }
        }
    }


    thead>tr>td,
    thead>tr>th,
    tbody>tr>td,
    tbody>tr>th,
    tfoot>tr>td,
    tfoot>tr>th {
        padding: 5px;
        border-color: $border-color;
        line-height: 1.5;
    }
}

table {
    td {
        padding: 5px;

        img {
            max-width: inherit;
        }
    }
}