$text-red:  #c7060a;

.ubermenu {
    max-width: 1240px;
    margin: 0 auto;

    .ubermenu-current-menu-item {
        color: $red !important;
        
        & > .ubermenu-target {
            color: $red !important;
        }
    }

    .ubermenu-item {

        @media #{$sm-max} {
            display: block;
        }

        &.ubermenu-item-level-0 {
            margin: 0 4px;

            &.ubermenu-current-menu-ancestor {
                & > .ubermenu-target {
                    color: $red;
                }
            }

            // "ordering" menu item
            &.ubermenu-item-7915 {
                float: left;
                margin-left: 0;

                & > a {
                    padding: 15px 0;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: $white;

                    & > span {
                        display: inline-block;
                        height: 30px;
                        padding: 5px 15px;
                        background: $text-color;
                        color: $white;
                    }

                    &:hover {
                        color: $white;
                    }
                }

                .on-scrolling & {
                    float: none;
                }
            }

            // "login" menu item
            &.ubermenu-item-8419 {
                float: right;
                margin-right: 0;

                & > a {
                    padding: 15px 0;
                    font-size: 14px;
                    line-height: 20px;
                    transition: all .25s;
                    text-transform: uppercase;


                    @media #{$md-min} {
                        & > span {
                            display: inline-block;
                            padding: 5px 15px;
                            height: 30px;
                            border: 1px solid #c4c9d0;
                        }
                    }

                    &:hover {
                        color: $brand-color;
                        border-color: $brand-color;
                    }

                    @media #{$sm-max} {
                        width: auto !important;
                        padding: 10px 15px;
                        margin: 5px 0;
                        border: none;
                        background: $text-color;
                        font: 400 12px/20px $default-font;
                        text-transform: uppercase;
                        color: #fff;
                    }
                }

                .on-scrolling & {
                    float: none;
                }
            }

            &:last-child {
                float: right;

                .on-scrolling & {
                    float: none;
                }
            }

            & > .ubermenu-target {
                padding: 20px 15px;
                font: 300 16px/20px $default-font;
                text-transform: none;
                color: #000;
            }

            & > a.ubermenu-target {

                &:hover {
                    color: $brand-color;
                }
            }
        }

        .ubermenu-target-text {
            display: inline;
            vertical-align: baseline;
        }
    }

    .ubermenu-submenu {

        @media #{$md-min} {
            display: block !important;
        }

        .ubermenu-item {
            font: 300 14px/22px $default-font;
            color: $text-color;
            height: auto;

            @media #{$sm-max} {
                font-size: 16px;
                line-height: 24px;
            }

            .ubermenu-target {
                color: inherit !important;
                line-height: inherit;
            }

            a.ubermenu-target {

                &:hover {
                    color: $text-red !important;
                }
            }
        }       

        .ubermenu-item-header {
            margin: 0 0 5px;
            font: 400 16px/18px $secondary-font;
            color: #000;
            font-size: 16px;

            @media #{$sm-max} {
                font-size: 18px;
            }

            .ubermenu-target {
                font-size: inherit !important;
            }
        }

        &.ubermenu-submenu-drop {
            height: auto;
            max-height: inherit;
            padding: 25px;
            box-shadow: none;
            background: #fff;
            border: 1px solid $border-color;
            border-top: none;

            /*.ubermenu-target {
                padding-right: 10px !important;
                font: 300 14px/22px $default-font;
                color: $text-color;

                &:hover {
                    color: $text-red;
                }

                @media #{$sm-max} {
                    font-size: 16px;
                    line-height: 24px;
                }
            }*/

            .ubermenu-item-header {
                & > .ubermenu-target {
                    margin: 0 0 5px;
                    //font-size: 14px;
                    //font-weight: 500;

                    @media #{$sm-max} {
                        font-size: 16px;
                    }

                    &.bordered-title {
                        position: relative;
                        display: block;
                        font: 400 14px/22px $secondary-font;
                        color: $text-red;

                        @media #{$md-min} {
                            margin-bottom: 15px;

                            &:after {
                                content: '';
                                display: block;
                                width: 50px;
                                height: 2px;
                                margin-top: 5px;
                                background: $text-red;
                            }
                        }

                        @media #{$sm-max} {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }

                &.ubermenu-item-level-2 {
                    & > .ubermenu-target {
                        //margin: 0 0 10px;
                        //margin: 0;
                        //font: 400 16px/24px $secondary-font;
                        //color: #000;

                        //@media #{$sm-max} {
                            //font-size: 18px;
                        //}
                    }
                }
            }
        }

        &.ubermenu-submenu-type-stack {
            padding: 0;
            margin: 0 0 25px;

            .ubermenu-divider {
                margin: 15px 0;
                padding: 0;
                min-height: 1px;

                hr {
                    display: none;
                }
            }
        }

        &.ubermenu-submenu-type-mega {}

        .spacer {
            opacity: 0;
        }
    }
}

@media #{$sm-max} {

    .ubermenu-responsive {
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        max-width: 100%;
        //max-height: 100%;
        padding: 20px;
        max-height: none;
        overflow-x: hidden !important;
        overflow-y: auto !important;
        transition: all .35s;
        background: #fff;

        &.ubermenu-responsive-collapse {
            max-height: inherit;
            overflow: hidden;
            visibility: hidden;
            right: -100%;
        }

        .ubermenu-item {
            &.ubermenu-item-level-0 {
                width: 100%;
                margin: 0;

                & > .ubermenu-target {
                    position: relative;
                    padding: 15px 0;
                }

                &.ubermenu-item-has-children {
                    & > .ubermenu-target:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 2px;
                        width: 8px;
                        height: 8px;
                        margin-top: -4px;
                        border-right: 1px solid $text-color;
                        border-bottom: 1px solid $text-color;
                        transform: rotate(-45deg);
                    }
                }

                &.ubermenu-active > .ubermenu-submenu-drop {
                    transform: translateX(0);
                }
            }

            &.ubermenu-item-level-2 {
                .ubermenu-submenu-type-stack {
                    padding-left: 10px;
                }
            }

            &.ubermenu-item-header .ubermenu-target {
                margin: 0 !important;
                padding: 15px 0;
            }
        }

        .ubermenu-submenu {
            margin: 0;
            opacity: 1;

            &.ubermenu-submenu-drop {
                height: auto;
                max-height: none;
                overflow: auto;
                position: absolute;
                top: 0 !important;
                bottom: 0 !important;
                padding: 20px;
                background: #fff;
                border: none;
                opacity: 1;
                box-shadow: none;
                visibility: visible;
                transition: all .35s !important;
                transform: translateX(100%);
                z-index: 99;

                .ubermenu-target {
                    padding: 10px 0;
                }
            }

            &.ubermenu-submenu-type-stack {
                margin: 0;

                .ubermenu-target {
                    padding: 10px 0;
                }

                & > .ubermenu-item-normal > .ubermenu-target {
                    padding: 3px 0;
                }
            }

            .ubermenu-target {
                padding: 3px 0;
            }
        }

        .ubermenu-column,
        .ubermenu-column-auto {
            min-width: 100% !important;
        }

        .ubermenu-retractor-mobile {
            display: none;
        }
    }

    .ubermenu-item-header > .ubermenu-submenu {
        display: none;
    }

    .ubermenu-item-header.ubermenu-active > .ubermenu-submenu {
        display: block;
    }

    .ubermenu-submenu-drop .ubermenu-item-has-children > .ubermenu-target {
        position: relative;
        padding-left: 20px !important;

        &:before {
            content: '+';
            position: absolute;
            top: 50%;
            left: 0;
            display: inline-block;
            width: 10px;
            height: 15px;
            margin-top: -10px;
            font-size: 20px;
            line-height: 16px;
            text-align: center;
            color: $text-color;
        }

        &.active:before {
            content: '-';
        }
    }

    .ubermenu-responsive-toggle {
        display: none;
    }

    // "Ordering" button
    .ubermenu-item-7915 > .ubermenu-target {
        display: none;
    }
}

