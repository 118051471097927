.cart-dropdown {
    visibility: hidden;
    position: absolute;
    width: 425px;
    max-width: 100%;
    max-height: 80vh;
    overflow: auto;
    top: 75px;
    right: 0;
    padding: 10px;
    border: 1px solid #eee;
    border-top: none;
    background: #fff url('#{$path-img}bg-pattern.png') center center;
    transition: all .35s;
    transform: translateY(10%);
    opacity: 0;
    z-index: 999;

    .header.cart-visible & {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
    }

    .header.on-scrolling & {
        top: 60px;

        @media #{$sm-max} {
            top: 120px;
        }
    }

    @media #{$sm-max} {
        text-align: left;
        top: 120px;
        z-index: 90;
    }

    .inner {
        padding: 10px;
    }

    .title {
        margin: -5px 0 15px;
    }

    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        @extend %close-button;
    }

    //.order-table {
    //    margin-bottom: 15px;
    //}

    .buttons-holder {
        position: relative;
        overflow: hidden;
        margin: 10px 0;

        .btn {
            margin: 5px 0;

            @media #{$xs-max} {
                padding: 8px 10px;
                font-size: 16px;
            }

            &:first-child {
                float: left;

                //@media #{$xs-max} {
                //    float: none;
                //}
            }

            &:last-child {
                float: right;

                //@media #{$xs-max} {
                //    float: none;
                //}
            }
        }

        //@media #{$xs-max} {
        //    text-align: center;
        //}
    }
}

.cart-table {
    table {
        width: 100%;
        font-size: 10px;
        line-height: 18px;
    }

    thead {
        tr {
            background: #eee;

            th {
                padding: 2px;
                border-bottom: 1px solid #fff;

                &:first-child {
                    border: none;
                }

                &.name {
                    width: 80px;
                }
            }
        }

    }

    tbody {
        tr {
            transition: all .25s;

            &:nth-child(even) {
                background: #eee;
            }

            &:nth-child(odd) {
                background: #f5f5f5;
            }

            &:hover {
                background: $text-color;
                color: $white;

                a {
                    color: $white;
                }
            }

            &.action-menu {
                td {
                    border-bottom: 1px solid #fff;
                }
            }

            td {
                &:first-child {
                    border: none;
                    border-right: 1px solid #fff;
                }
            }
        }
    }

    tfoot {
        tr {
            background: #eee;
        }
    }

    td {
        padding: 2px;

        &:first-child {
            width: 20px;
            background: #eee;
            border: none;
            text-align: center;
        }

        a {
            text-decoration: underline;
        }

        input {
            width: 30px;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            color: $text-color;
        }
    }
}

.cart-info-msg {
    display: none;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
    color: #00aa00;
}