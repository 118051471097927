.login-form {
    @media #{$xs-max} {
        margin-bottom: 30px;
    }

    .form-group {
        margin: 0 0 30px;
    }

    .form-control {
        width: 100%;
    }

    .button-group {
        overflow: hidden;

        .btn {
            vertical-align: middle;
            float: left;
            margin-right: 20px;
        }

        .link {
            display: inline-block;
            vertical-align: middle;
            padding: 10px 0;
            line-height: 20px;
        }
    }

    .form-errors {
        padding: 10px;
        margin: 0 0 30px;
        border: 1px solid $red;
        transition: all .25s;
        font-size: 12px;
        color: $red;

        &:hover {
            background: rgba($red, .1);
        }
    }
}