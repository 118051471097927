.search-box {
    position: fixed;
    //top: 60px;
    top: 0;
    left: 0;
    transform: translateY(0);
    visibility: hidden;
    opacity: 0;
    height: auto;
    width: 100%;
    transition: all .35s;
    z-index: 800;
    font-size: 16px;
    line-height: 40px;

    @media #{$xs-max} {
        font-size: 14px;
    }

    .container {
        max-width: 1240px;
        padding: 10px;
        background: url('#{$path-img}/bg-pattern.png') center center repeat;

        @media #{$md-max} {
            padding: 10px 20px;
        }

        @media #{$xs-max} {
            padding: 10px;
        }
    }

    .inner {
        padding: 10px 15px;
        background: #fff;

        @media #{$sm-max} {
            padding: 5px 15px 10px;
        }

        @media #{$xs-max} {
            padding: 5px 10px 10px;
        }
    }

    label {
        margin: 0;
        font-weight: normal;
    }

    .radio-control {
        margin-left: 10px;

        @media #{$xs-max} {
            margin-left: 5px;
        }
    }

    .form-control {
        width: 100%;
        //border-color: rgba(0, 0, 0, .08);
        border-color: $border-color;
        background: #fff;

        &:hover,
        &:focus {
            border-color: $text-color;
        }
    }

    .search-input {
        position: relative;
        padding-right: 40px;

        .btn {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 40px;
            height: 40px;
            padding: 5px 0 5px 5px;
            border: none;
            background: none;
            font: 400 25px/30px $segoe;
            text-align: right;
            color: $text-color;

            &:hover {
                color: $brand-color;
            }
        }
    }
}

.layout {
    &.search-active {
        .search-box {
            //top: 150px;
            transform: translateY(150px);
            visibility: visible;
            opacity: 1;

            @media #{$sm-max} {
                //top: 120px;
                transform: translateY(120px);
            }
        }
    }

    &.on-scrolling.search-active {
        .search-box {
            position: fixed;
            //top: 60px;
            transform: translateY(60px);
        }
    }
}