legend {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $border-color;
    font: 400 20px/1 $secondary-font;
    color: $text-color;
}

.form-group {
    position: relative;
    margin-bottom: 30px;

    @media #{$xs-max} {
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    //&.required {
    //    &:after {
    //        content: '*';
    //        position: absolute;
    //        top: 0;
    //        right: 100%;
    //        display: inline-block;
    //        width: 20px;
    //        height: 40px;
    //        padding: 5px;
    //        font: 600 18px/30px $default-font;
    //        text-align: center;
    //        color: $red;
    //    }
    //}
}

.radio-group {
    margin-bottom: 30px;

    @media #{$xs-max} {
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .radio-control:not(.radio-block) {
        margin-right: 20px;
    }
}

label {
    margin: 0 0 10px;
    font: 400 16px/20px $default-font;

    &.label-block {
        display: block;
    }
}

.form-control {
    display: inline-block;
    width: auto;
    height: 40px;
    padding: 5px 10px;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2 !important;
    @include placeholder(#909090);
    transition: border .25s;
    font: 400 16px/30px $georgia, sans-serif;
    color: $text-color;

    &:hover, &:focus {
        border-color: $text-color;
        box-shadow: none;
    }

    &:disabled,
    &[disabled=disabled] {
        background-color: #ddd !important;
        border-color: #aaa;
    }

    &.block {
        display: block;
        width: 100%;
    }

    @media #{$xs-max} {
        padding: 5px 10px;
    }

    .has-error & {
        border-color: $red;

        &:focus {
            box-shadow: none;
            border-color: $red;
        }

        @include placeholder($red);
    }
}

textarea.form-control {
    max-height: 240px;
    resize: none;

    @media #{$xs-max} {
        height: 120px;
    }
}

.radio-control {
    position: relative;
    display: inline-block;
    vertical-align: top;

    &.radio-block {
        display: block;
    }

    input {
        position: absolute;
        opacity: 0;
        margin: 0;

        &:checked + label:before {
            content: '\eccb';
        }
    }

    label {
        position: relative;
        padding-left: 30px;
        margin: 5px 0;

        &:before {
            content: '\ecca';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            font: 400 20px/1 $segoe;
            color: $text-color;
        }
    }
}

.checkbox-control {
    position: relative;
    display: inline-block;
    //padding: 5px 0;

    &.checkbox-block {
        display: block;
    }

    input {
        position: absolute;
        opacity: 0;
        margin: 0;

        &:checked + label:before {
            content: '\e005';
        }

        &:disabled + label {
            opacity: .5;
        }
    }

    label {
        position: relative;
        padding-left: 30px;
        margin: 5px 0;

        &:before {
            content: '\e003';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            //top: 50%;
            top: 0;
            left: 0;
            //margin: -10px 0 0;
            font: 400 20px/1 $segoe;
            color: $text-color;
        }
    }
}

.button-group {
    margin: 30px 0;

    &:last-child {
        margin-bottom: 0;
    }
}

//select2
.select2-container--default {
    
    .select2-selection--single {
        @extend .form-control;
        display: block;
        width: 100%;

        .select2-selection__rendered {
            color: inherit;
            line-height: inherit;
            padding: 0;
        }

        .select2-selection__arrow {
            top: 0;
            right: 0;
            height: 100%;
        }
    }
}