.product-description {
    position: relative;
    overflow: hidden;
    margin: 0 0 40px;

    p {
        margin: 0 0 10px;

        &:empty {
            display: none;
        }

        img {
            float: left !important;
            max-width: 100%;
            margin: 5px 20px 20px 0 !important;

            @media #{$xs-max} {
                margin: 5px 20px 10px 0 !important;
            }

            @media #{$xm-max} {
                //width: 100% !important;
                height: auto !important;
            }
        }
    }

    p, span {
        font: 400 14px/22px $default-font !important;
        color: #414449 !important;
    }

    strong {
        font-family: $default-font !important;
        font-size: 14px !important;
    }

    a:not(.btn) {
        color: $brand-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.product-details {

    .product-image-col {
        text-align: center;

        // image zoom container
        table {
            td {
                padding: 0;
            }
        }

        .product-image {
            display: inline-block;
            width: 175px;
        }

        .product-image-hint {
            margin: 5px 0;
            font-size: 80%;
            text-align: center;
        }

        @media #{$xm-max} {
            width: 100%;
            margin: 0 0 20px;
        }
    }

    .product-info-col {
        @media #{$xm-max} {
            width: 100%;
            text-align: center;

            table {
                display: inline-block;
            }
        }
    }

    .form-control.item-quantity {
        text-align: center;
    }
}

.product-video {
    margin-top: 30px;

    iframe {
        display: block;
        margin-bottom: 15px;
    }
}

#profile_description {

    #product_rating {
        display: flex;
        align-items: center;

        .holder {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
        }

        .add-review,
        .total {
            cursor: pointer;

            &:hover {
                color: $brand-color;
            }
        }

        .stars {
            padding: 0 10px 0 0;
        }
    }
}