.dropdown {

    &.open > .btn-default.dropdown-toggle {
        border: 1px solid $border-color;
        background: #f5f5f5;
    }

    .dropdown-menu {
        right: 0;
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: 1px solid $border-color;
        border-top: 0;

        box-shadow: none;

        & > li {

            & > a {
                padding: 10px 20px;
            }
        }
    }
}