.checkout-page {
    transition: none;

    .container {
        transition: none;
    }

    .header {
        .header-navbar {
            @media #{$sm-max} {
                padding: 0;
            }
        }
    }

    .sidebar {
        margin: 0;
    }

    .shipping-info-col {
        @media #{$xs-max} {
            margin-top: 20px;
        }
    }
}

.checkout-menu {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 0;

    @media #{$xm-max} {
        padding: 7px;
    }

    li {
        position: relative;
        display: inline-block;
        margin: 0 5px;

        &:not(:last-child):after {
            content: '\f105';
            position: relative;
            right: -5px;
            font: 400 16px/1 FontAwesome;
            color: $dark-grey;
        }

        a {
            display: inline-block;
            padding: 20px 15px;
            transition: all .25s;
            font: 300 16px/20px $default-font;
            color: $dark-grey;

            @media #{$xm-max} {
                padding: 0 15px;
                font-size: 15px;
            }

            &:hover,
            &.active {
                color: $red;
            }
        }
    }
}

// checkout-sidebar
.checkout-sidebar {
        margin: 0 auto;
        border-collapse: separate;
        border-spacing: 1px;
        background-color: $border-color;

        tr {
            background-color: #fff;

            td {
                padding: 5px 10px;
                text-align: center;

                a {
                    display: inline-block;
                    margin: 2px;
                }
            }
        }

        h3 {
            font-size: 20px;
            margin: 0;
        }

        #trust_guard,
        #trust_wave {
            padding-bottom: 10px;
        }

        #authorize_net {
            padding-bottom: 0;
        }
    }

.checkout-form {

    h3 {
        margin-bottom: 20px;
    }

    .form-group {
        position: relative;

        .form-control {
            position: relative;
            width: 100%;
            z-index: 20;

            &:focus + .label.visible-on-focus {
                opacity: 1;
                top: -20px;
            }
        }

        .label.visible-on-focus {
            position: absolute;
            opacity: 0;
            top: 0;
            right: 0;
            margin: 0;
            transition: all .25s;
            font: 13px/1.5 $default-font;
            color: $text-color;
            z-index: 10;
        }
    }

    blockquote.form-group {
        margin: 20px 0;
        padding: 0 20px;
        border-left: 3px solid $border-color;
    }
}

.checkout-summary-table {
    fieldset {
        margin-bottom: 30px;

        @media #{$xs-max} {
            margin-bottom: 20px;
        }

        &:last-child {
            margin: 0;
        }
    }

    legend {
        overflow: hidden;
        padding: 0 5px 10px;

        a {
            float: right;
            margin-left: 10px;
            font-size: 14px;
            line-height: 20px;
        }

        &.no-margin {
            margin: 0;
        }

        &.no-border {
            border: none;
        }
    }

    caption {
        margin: 0;
        padding: 0 5px 10px;
        font: 500 18px/1.2 $default-font;
        text-align: center;
        color: $text-color;

        @media #{$xs-max} {
            font-size: 17px;
            font-weight: 500;
            text-align: left;
        }
    }

    .table {
        margin-bottom: 20px;

        td,
        th {
            border: 1px solid $border-color;
        }
    }
}

.missing-anything {
    h4 {
        margin-bottom: 25px;
    }
}