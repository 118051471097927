.modal {
    padding: 20px !important;

    .modal-dialog {
        //margin: 20px;
        margin: 0;
        max-width: 100%;

        @media #{$sm-min} {
            margin: 50px auto;
        }

        &.modal-lg {
            width: 1000px;
        }

        &.modal-md {
            width: 800px;
        }

        &.modal-sm {
            width: 600px;
        }

        &.modal-xs {
            width: 400px;
        }
    }

    .modal-content {
        width: 100%;
        height: auto;
        border: none;
        box-shadow: none;
        border-radius: 0;
        background: #fff url('#{$path-img}/bg-pattern.png');
    }

    .modal-header {
        padding: 20px;
        border-bottom: 1px solid $border-color;

        h3 {
            margin: 0;
        }
    }

    .modal-body {
        padding: 20px;
        font-size: 14px !important;

        & > p {
            font-size: 14px !important;
        }

        td {
            font-size: 14px !important;
        }
    }

    .modal-footer {
        padding: 20px;
        border: none;
        font-size: 14px !important;

        .btn + .btn {
            margin-left: 15px;
        }
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        @extend %close-button;
        text-decoration: none;
    }
}

.login-modal {
    .modal-header {
        //text-align: left;
        font: 400 14px/1em $default-font;
        vertical-align: middle;
        color: $text-color;
        text-transform: uppercase;
        border-radius: 0;
        border: none;
        padding: 50px;
        text-align: center;

        @media #{$xs-max} {
            padding: 30px 20px;
        }

        span.title {
            padding-left: 20px;
            position: relative;
            top: 7px;
            letter-spacing: 0.045em;

            @media #{$xs-max} {
                display: block;
                padding: 10px 0 0;
            }
        }

        img {
            max-width: 100%;
        }
    }

    .modal-body {
        border-radius: 0;
        border: none;
        background: transparent;
        padding: 0 50px 50px;
        box-shadow: none;

        @media #{$xs-max} {
            padding: 0 20px 30px;
        }
    }

    .login-box {
        max-width: 300px;
        margin: 0 auto;

        form {
            overflow: hidden;
        }

        label {
            display: block;
        }

        .form-control {
            width: 100%;
        }

        .error-msg {
            display: none;
            padding: 10px;
            margin-bottom: 20px;
            border: 1px solid $red;
            background: #fff;
            font-size: 12px;
            color: $red;
        }
    }
}

.modal-backdrop + .modal-backdrop  {
    z-index: 1050;
}

.exceeds-total-frame-depth-modal {
    z-index: 2000;
}