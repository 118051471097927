.btn {
    transition: all .25s;
    @include button-size(12px, 24px, 14px, 18px, 0);
    font-family: "Open Sans", sans-serif;
    box-shadow: none;
    text-shadow: none;

    i {
        margin-left: 5px;
    }
}

.btn-primary {
    @include button-variant($white, $brand-color, transparent);
    border-color: $brand-color;

    &:hover,
    &:focus,
    &:active:focus {
        outline: none;
    }
}

.btn-default {
    @include button-variant($brand-color, $white, transparent);
    border-color: #c4c9d0;

    &:hover,
    &:focus,
    &:active:focus {
        border-color: $border-color;
        background: #f5f5f5;
        outline: none;
    }
}

.btn-dark {
    @include button-variant($white, $dark-grey, transparent);
    border-color: $dark-grey;

    &:hover,
    &:focus,
    &:active:focus {
        border-color: $dark-grey;
        background: rgba($dark-grey, .85);
        outline: none;
    }
}

.fl-builder-content .fl-module-button.bb-red-button a.fl-button {
    @extend .btn-primary;
    border-radius: 0;
}

.fl-builder-content .fl-module-button.bb-red-button a.fl-button span {
    color: #fff;
}