// Path
$path-fonts : '../fonts/';
$path-img   : '../images/';

// Fonts
$open-sans : 'Open Sans';
$georgia   : 'Georgia';
$novecento : 'Novecentoswide';
$segoe     : 'Segoe MDL2 Assets', 'Segoe-MDL2-Assets';

$default-font   : $open-sans, sans-serif;
$secondary-font : $georgia, sans-serif;

$text-font  : $default-font;
$title-font : $secondary-font;

// Colors
$white: #fff;
$black: #000;
$dark-grey:  #414449;
$light-grey: #d6d9de;
$red: #c7060a;

$brand-color  : $red;
$title-color  : $dark-grey;
$text-color   : $dark-grey;
$border-color : $light-grey;

// Grid Layout
$lg-min: 'screen and (min-width: #{$screen-lg})';
$md-only: 'screen and (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1})';
$md-max: 'screen and (max-width: #{$screen-lg - 1})';
$md-min: 'screen and (min-width: #{$screen-md})';
$sm-only: 'screen and (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1})';
$sm-max: 'screen and (max-width: #{$screen-md - 1})';
$sm-min: 'screen and (min-width: #{$screen-sm})';
$xs-only: 'screen and (min-width: #{$screen-xs}) and (max-width: #{$screen-sm - 1})';
$xs-max: 'screen and (max-width: #{$screen-sm - 1})';
$xs-min: 'screen and (min-width: #{$screen-xs})';

$xm-max: 'screen and (max-width: 450px)';

// Animation
$animation-speed: .35s;

