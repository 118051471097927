#toTop {
	background-color: #333;
    border-radius: 4px 4px 0 0;
    bottom: 0;
    color: #fff;
    display: block;
    height: 35px;
    position: fixed;
    right: 70px;
    text-align: center;
    text-transform: uppercase;
    width: 48px;
    z-index: 100000;
    transition: all .2s ease-in-out 0s;

    &:hover {
    	background-color: $brand-color;
    }

    &:before {
    	position: absolute;
    	left: 50%;
    	top: 50%;
    	transform: translate(-50%, -50%);
    }
}