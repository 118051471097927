footer {
    position: relative;
    border-top: 4px solid #f4f4f4;
    background: #fff;
    z-index: 100;
    font: 400 14px/22px $default-font;
    color: #909296;
    text-align: left;
    @extend .hidden-print;

    @media #{$xs-max} {
        //padding-top: 180px;
        padding-top: 170px;
    }

    .row {
        @include custom-row(40px);
    }

    div, p {
        font-size: inherit;
        color: #909296;
    }

    .footer-title {
        margin: 0 0 10px;
        font: 500 14px/20px $novecento, sans-serif;
        text-transform: uppercase;
        color: #414449;
    }

    .footer-block {
        padding: 75px 0;

        @media #{$xs-max} {
            padding: 35px 0;
        }
    }

    .footer-col-left {
        width: 26.3%;
        border-right: 1px solid #f4f4f4;

        @media #{$sm-max} {
            width: 33.33333%;
            padding: 0 20px;
        }

        @media #{$xs-max} {
            width: 100%;
        }

        .footer-block {
            @media #{$xs-max} {
                padding: 35px 0 0;
            }
        }
    }

    .footer-col-right {
        position: inherit;
        width: 73.7%;
        padding-left: 145px;

        @media #{$md-max} {
            padding: 0 20px;
        }

        @media #{$sm-max} {
            width: 66.66667%;
        }

        @media #{$xs-max} {
            width: 100%;
        }

        .footer-block {

            @media #{$md-max} {
                width: 500px;
                margin: 0 auto;
            }

            @media #{$sm-max} {
                width: 100%;
            }

            @media #{$xs-max} {
                padding: 20px 0;
            }
        }

        p {
            @media #{$xs-max} {
                margin: 0 0 20px;
            }
        }
    }

    .contact-info {
        margin-top: 20px;

        @media #{$xs-max} {
            margin-top: 10px;
        }

        dt {
            float: left;
            display: inline-block;
            width: 55px;
        }

        dd {
            margin-bottom: 8px;
        }
    }

    .social-networks {
        margin-top: 48px;

        @media #{$xs-max} {
            margin-top: 28px;
        }

        a {
            margin-right: 15px;
            color: #909296;

            &:hover {
                text-decoration: none;
                color: #414449;
            }

            @media #{$xs-max} {
                margin-right: 30px;
            }
        }

        i {
            font-size: 20px;
        }
    }

    .signup-form {
        margin: 0 auto 85px;

        @media #{$sm-max} {
            margin-bottom: 65px;
        }

        @media #{$xs-max} {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: 35px 20px;
            margin: 0;
        }

        form {
            position: relative;
            width: 500px;

            @media #{$sm-max} {
                width: 100%;
            }

            .form-control {
                width: 100%;
                height: 40px;
                padding: 10px 20px;
                border-radius: 0;
                background: #f2f2f3;
                font: 400 14px/20px $secondary-font;
                color: #909296;

                @media #{$xs-max} {
                    padding: 10px 15px;
                }
            }
        }

        .btn {
            width: 90px;
            height: 40px;
            padding: 5px 10px;
            border-radius: 0;
            outline: none;
            border: 1px solid #c4c9d0;
            background: #fff;
            position: absolute;
            top: 0;
            right: 0;
            font-family: $segoe;
            font-size: 24px;
            color: #c7060a;

            &:focus,
            &:active:focus {
                outline: none;
            }

            @media #{$xs-max} {
                width: 80px;
            }

            .win-icon {
                vertical-align: top;
                margin: 0;
                font-size: 24px;
            }
        }
    }

    .copyright {
        font-size: 12px;

        a {
            text-decoration: underline;
        }
    }

    a {
        color: #909296;

        &:hover {
            text-decoration: underline;
            color: #909296;
        }

        &:visited {
            color: #909296;
        }
    }
}