.main-content {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 100px;
    z-index: 20;

    @media #{$xs-max} {
        padding-bottom: 50px;
    }

    .content {

    }

    .breadcrumbs {
        min-height: 22px;
        margin: 0 0 20px;

        @media print {
            display: none !important;
        }

        br {
            display: none;
        }

        a {
            color: inherit;

            &:hover {
                color: $brand-color;
            }
        }
    }
}

.info-popup .text p {
    overflow: hidden;
}