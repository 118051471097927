body {
    overflow-y: scroll;
}

.page-heading {
    margin: 0 0 15px;

    @media #{$sm-min} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        margin-bottom: 15px;
        flex-shrink: 1;
    }

    .info {
        padding: 8px 0;
        margin-bottom: 15px;

        @media #{$sm-min} {
            text-align: right;
            padding-left: 20px;
        }

        @media #{$md-min} {
            flex-shrink: 0;
        }
    }
}

.page-bottom {
    display: none;
    padding: 30px 0 0;

    @media #{$xs-max} {
        display: block;
    }
}

.profile-options,
.mat-main {
    counter-reset: list;

    .description {
        overflow: hidden;

        img {
            display: block;
            margin-bottom: 30px;
            max-width: 100%;

            @media #{$md-min},
            screen and (min-width: 560px) and (max-width: #{$screen-sm - 1}) {
                float: left;
                margin-right: 30px;
                max-width: 50%;
            }
        }
    }

    .section {
        margin: 0 0 35px;
        opacity: 1;
        

        &:last-child {
            margin-bottom: 0;
        }

        &.description {

            .btn-row {
                margin-top: 25px;
            }

            @media screen and (max-width: 450px) {
                .image {
                    width: 100%;
                    margin: 0 0 25px;
                    text-align: center;
                }

                .text {
                    width: 100%;
                }
            }
        }

        &.dimensions {
            @media screen and (max-width: 450px) {
                .dim-box {
                    width: 100%;
                    margin: 0 0 35px;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        &.off {
            .section-content {
                opacity: 0;
            }

            .section-toggle {

                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .section-title {
        position: relative;

        &:before {
            counter-increment: list;
            content: counter(list) ". ";
        }

        .section-toggle {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            padding: 5px 2px;
            text-align: right;
            color: $text-color;

            &:hover {
                color: #d6d9de;
            }

            &:after {
                content: '\00e099';
                font: 400 22px/1 $segoe;
                transform: rotate(0);
                transition: all .25s;
                display: inline-block;
            }
        }
    }

    .section-content {
        transition: opacity .3s;
    }

    .section-content-econospace {
        
        p {
            margin-bottom: 10px;
        }
    }

    hr {
        margin: 35px 0 0;
        border-top: 1px solid $border-color;
    }

    .section-title {
        padding: 0 30px 10px 0;
        margin: 0 0 20px;
        border-bottom: 1px solid $border-color;
        font-size: 20px;

        span {
            margin-right: 8px;
        }
    }

    .subtitle {
        margin: 0 0 10px;
    }

    .option-list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .radio-control.visual {
            max-width: 20%;
            flex: 0 0 20%;
            margin: 0;
            padding: 10px;
            display: flex;

            @media #{$md-max} {
                max-width: 33.3333%;
                flex: 0 0 33.3333%;
            }

            @media screen and (max-width: 450px) {
                max-width: 50%;
                flex: 0 0 50%;
            }

            @media screen and (max-width: 350px) {
                max-width: 100%;
                flex: 0 0 100%;
            }

            label {
                position: relative;
                overflow: hidden;
                display: block;
                width: 100%;
                margin: 0;
                padding: 110px 10px 10px 40px;
                border: 1px solid $border-color;
                transition: all .25s;
                text-decoration: none;
                font-size: 14px;
                line-height: 1.5;
                color: $text-color;

                @media #{$sm-max} {
                    font-size: 12px;
                }

                &:hover {
                    border-color: $text-color;
                }

                &:before {
                    top: 110px;
                    left: 10px;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100px;
                }

                .image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100px;
                    //border-bottom: 1px solid $border-color;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    text-align: center;

                    img {
                        position: relative;
                        width: auto;
                        height: auto;
                        max-height: 100%;
                        opacity: 0;
                    }
                }
            }

            .image {
                &.strainer-image {
                    background-size: auto 90%;
                }
            }

            &.double-height {
                label {
                    padding-top: 160px;

                    &:before {
                        top: 160px;
                    }
                }

                .image {
                    height: 150px;
                    background-size: cover;
                }
            }
        }

        .dim-error {
            display: flex;
            width: 100%;
            margin: 5px 10px;
            color: inherit;
        }
    }

    .option-box {
        @media screen and (max-width: 450px) {
            width: 100%;
            margin: 0 0 30px;

            &:last-child {
                margin: 0;
            }
        }
    }

    input[type=text].form-control,
    input[type=number].form-control,
    input[type=tel].form-control {
        float: left;
        width: 95px;
        margin-right: 15px;

        &.sm {
            width: 100px;
        }
    }

    select.form-control {
        width: 90px;
    }

    .radio-control {
        margin-right: 30px;
        font: 400 16px/22px $default-font;

        &.radio-block {
            margin: 0;
        }

        label {
            span {
                word-break: break-word;
                margin-right: 8px;
            }
        }
    }

    .msc-container {
        display: block;
    }

    .info-toggle {
        color: red;
        cursor: pointer;
        position: relative;
        z-index: 10;
    }

    .spacer-size-holder {

        @media #{$md-min} {
            > * {
                display: inline-block;
                vertical-align: middle;
            }

            .radio-group {
                margin-bottom: 0;
            }

            a {
                margin: 0 0 0 20px;
            }
        }
    }
}

.info-popup {
    display: none;
    padding: 10px;
    margin: 30px 0;
    background: url('#{$path-img}bg-pattern-grey-2.png');

    @media #{$xs-max} {
        padding: 20px;
        margin: 20px -20px;
    }

    .inner {
        position: relative;
        padding: 35px 20px;
        background: $white;
    }

    .close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        color: #000;

        &:hover {
            color: #f00;
        }
    }

    .table-responsive {
        @media #{$xs-max} {
            padding: 10px;
            border: 1px solid $border-color;
        }
    }
}

.spacers-size-info,
.strainer-drill-info {
    .image-holder {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            width: 100%;
        }
    }

    .media-holder {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            width: 100%;
        }

        .media-box {
            @media screen and (max-width: 450px) {
                width: 100%;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

// Bottom Section with Price and Buttons
.bottom-section {

    .row {
        margin: 0;
    }

    .form-group {
        overflow: hidden;
    }

    .holder {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: flex-end;
        margin: 0 -8px;
    }

    .item {

        > * {
            margin: 0 8px 16px;

            @media #{$xm-max} {
                margin: 8px 0;
            }
        }
    }

    &.is-edit {

        .holder {

            @media #{$md-max} {
                justify-content: space-between;
            }
        }

        .button-holder {
            @media #{$md-max} {
                flex-wrap: wrap;
            }
        }

        .btn-update {
            display: flex;
        }
    }

    &.has-package{

        .btn-view {
            display: flex;
        }
    }

    .button-holder {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-grow: 1;
    }

    .btn,
    .dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 140px; 

        @media #{$xm-max} {
            min-width: 100%;
            width: 100%;
        }
    }

    .btn-update,
    .btn-view {
         display: none;
    }

    .price-holder {
        text-align: left;
        align-self: flex-start;
        margin-right: auto;
        flex-grow: 100;

        .price {
            border: none;
            min-width: 140px;
            padding: 0 10px 5px;
            margin: 0 !important;
            background: url('#{$path-img}/bg-pattern-grey.png');
            font: 400 24px/30px $secondary-font;
            text-align: center;
            color: $text-color;
            height: 44px;
        }
    }

    .btn-holder {
        float: right;
        width: auto;
        padding: 30px 0 0;
        text-align: right;
        font-size: 0;

        @media #{$sm-max} {
            width: 100%;
        }

        .btn {
            margin-left: 15px;

            @media #{$md-max} {
                padding: 8px 15px;
            }

            @media screen and (max-width: 450px) {
                width: 100%;
                margin-top: 15px;
            }

            &:last-child {
                @media #{$sm-max} {
                    float: right;
                }
            }

            &:first-child {
                margin: 0;

                @media #{$sm-max} {
                    float: left;
                }
            }
        }

        .dropdown {
            display: inline-block;
            width: 160px;
            margin-left: 15px;

            .btn {
                width: 100%;
                margin: 0;
            }

            @media #{$xm-max} {
                width: 100%;
                margin: 20px 0 0;
            }
        }
    }
}

// frame tab
#lengthsarea,
#cut2sizearea {
    visibility: hidden;
    display: none;
}

// mat tab
.matStyleCalculator-container {
    margin-bottom: 20px;

    .radio-group {
        margin: 0;
    }
}

.mat-calc-table {
    position: relative;

    table {
        margin-top: 0;

        @media #{$sm-max} {
            margin: 0 0 20px;
        }
    }

    thead {
        th {
            padding: 8px 5px;
            border-bottom: 1px solid $light-grey;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    tbody {
        td {
            padding: 10px 5px 0;

            &:first-child {
                padding-left: 0;
                padding-right: 15px;

                @media #{$xs-max} {
                    width: 120px;
                }
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    select.form-control,
    input[type=text].form-control,
    input[type=number].form-control,
    input[type=tel].form-control {
        margin: 0;

        @media screen and (max-width: 450px) {
            float: none !important;
        }
    }

    .title-of-artwork.form-control {
        width: 100% !important;
        max-width: 195px;
    }

    label {
        margin: 0;
        font-size: 14px;
    }
}

.msc-container,
.msct-container {
    label {
        font-size: 14px;
    }
}

.dim-error {
    color: red;
    white-space: normal;
    display: none;
    padding: 20px;
    margin: 30px 0;
    border: 10px solid red;
    border-image: url(../images/bg-pattern-red.png) 12 round round;

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.mat-error {
    font-size: 12px;
    display: block;
    color: red;
    text-align: left;
}

a.new-feature {
    color: #f00;
    text-decoration: underline;
    font-style: italic;
}

#goto-menu,
#goto-menu-sidebar {

    li {
        a {
            position: relative;
            display: block;
            padding-left: 40px;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 10px;
                margin-top: -8px;
                font: 400 16px/1 $segoe;
                color: #d6d9de;
            }
        }

        &.edited {
            a:before {
                content: '';
                color: #d6d9de;
            }
        }

        &.added {
            a:before {
                content: '';
                color: $dark-grey;
            }
        }
    }
}

#howLongModal {
    .style7,
    .qship {
        font-size: 10px;
        padding: 1px 2px 1px 2px;
    }

    .qship {
        color: #f00;
        font-weight: bold;
        background-color: #eee;
    }

    .style8 {
        padding: 4px;
        font-size: 12px;
        font-weight: bold;
    }
}