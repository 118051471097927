// Includes font $font-face.
@mixin font-face ($name, $file-name, $font-weight: 400, $font-style: normal) {
  @font-face {
    font-family: $name;
    src: url('#{$path-fonts}#{$file-name}.eot');
    src: url('#{$path-fonts}#{$file-name}.eot?#iefix') format('embedded-opentype'),
         url('#{$path-fonts}#{$file-name}.woff') format('woff'),
         url('#{$path-fonts}#{$file-name}.woff2') format('woff2'),
         url('#{$path-fonts}#{$file-name}.ttf') format('truetype');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

/* Position */

@mixin pos-l-t ($l: 0, $t: 0) {
    left: $l;
    top: $t;
}

@mixin pos-l-b ($l: 0, $b: 0) {
    left: $l;
    bottom: $b;
}

@mixin pos-r-t ($r: 0, $t: 0) {
    right: $r;
    top: $t;
}

@mixin pos-r-b ($r: 0, $b: 0) {
    right: $r;
    bottom: $b;
}

@mixin pos-a-l-t ($l: 0, $t: 0) {
    position: absolute;
    @include pos-l-t($l, $t);
}

@mixin pos-a-l-b ($l: 0, $b: 0) {
    position: absolute;
    @include pos-l-b($l, $b);
}

@mixin pos-a-r-t ($r: 0, $t: 0) {
    position: absolute;
    @include pos-r-t($r, $t);
}

@mixin pos-a-r-b ($r: 0, $b: 0) {
    position: absolute;
    @include pos-r-b($r, $b);
}

@mixin pos-r-l-t ($l: 0, $t: 0) {
    position: relative;
    @include pos-l-t($l, $t);
}

@mixin pos-r-l-b ($l: 0, $b: 0) {
    position: relative;
    @include pos-l-b($l, $b);
}

@mixin pos-r-r-t ($r: 0, $t: 0) {
    position: relative;
    @include pos-r-t($r, $t);
}

@mixin pos-r-r-b ($r: 0, $b: 0) {
    position: relative;
    @include pos-r-b($r, $b);
}

@mixin pos-f-l-t ($l: 0, $t: 0) {
    position: fixed;
    @include pos-l-t($l, $t);
}

@mixin pos-f-l-b ($l: 0, $b: 0) {
    position: fixed;
    @include pos-l-b($l, $b);
}

@mixin pos-f-r-t ($r: 0, $t: 0) {
    position: fixed;
    @include pos-r-t($r, $t);
}

@mixin pos-f-r-b ($r: 0, $b: 0) {
    position: fixed;
    @include pos-r-b($r, $b);
}

@mixin custom-row ($offset) {

    margin-left: -$offset/2;
    margin-right: -$offset/2;

    & > * {
        padding-left: $offset/2;
        padding-right: $offset/2;
    }
}

%arr {
  @include square(0);
  border-color: transparent;
  border-style: solid;
}

@mixin arrow($width: 20px, $direction: up, $color: inherit){
    @extend %arr;
    border-width: $width;
  
    // Right
    @if $direction == right {
        border-left-color: $color;
    }

    // Left
    @if $direction == left {
        border-right-color: $color;
    }

    // Up
    @if $direction == up {
        border-bottom-color: $color;
    }

    // Down
    @if $direction == down {
        border-top-color: $color;
    }
}

/* Clear offset */
%clear-offset {
	margin: 0;
	padding: 0;
}

/* Clear list */
%clear-list {
	list-style: none;
	@extend %clear-offset;
}

/* Generate rows */
@mixin custom-row($offset) {

	margin-left: -$offset/2;
	margin-right: -$offset/2;

	& > * {
		padding-left: $offset/2;
		padding-right: $offset/2;
	}

}

@mixin clear-row() {

	& > * {
		width: 100%;
	}
}

@mixin round($size) {
    @include square($size);
    border-radius: $size/2;
    background-clip: border-box;
}


%font-awesome {
	display: inline-block;
	font-family: FontAwesome;
	font-size: inherit;
	line-height: 1;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%full-screen {
  @include square(100%);
}

%close-button {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    color: $text-color;

    &:hover {
        color: $brand-color;
    }
}