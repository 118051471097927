.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 135px;
    margin: 0 auto;
    background: #fff;
    border-bottom: 1px solid $border-color;
    transition: height .35s ease;
    z-index: 900;

    .modal-open & {
        padding-right: 17px;

        @media #{$md-max} {
            padding-right: 0;
        }
    }

    @media #{$sm-max} {
        height: 120px;
    }

    @media print {
        height: auto !important;
        position: static !important;
        top: 0 !important;
        text-align: center;
        border: none !important;
    }

    &.on-scrolling {
        height: 60px;

        .header-top {
            height: 60px;
            padding: 12.5px 0;
            border-color: transparent;

            @media #{$sm-max} {
                transform: translateY(-60px);
            }
        }

        .header-phone {
            opacity: 0;
        }

        .header-logo {

            @media #{$md-min} {
                width: 60px;
                left: 0;
                top: 12px;
                transform: translate(0, 0);
            }
        }

        .header-navbar {
            @media #{$md-min} {
                transform: translate(0, -100%);
            }

            @media #{$sm-max} {
                top: -60px;
            }
        }
    }

    .container {
        max-width: 1280px;
        max-height: 100%;
        padding: 0 20px;

        @media #{$sm-max} {
            padding: 0;
        }
    }

    .header-top {
        position: relative;
        max-width: 1240px;
        height: 75px;
        margin: 0 auto;
        padding: 20px 0;
        transition: all .35s;
        border-bottom: 1px solid $border-color;

        @media #{$sm-max} {
            height: 60px;
            padding: 12.5px 0;
            text-align: center;
        }

        @media print {
            height: auto !important;
            border: none !important;
        }
    }

    .header-phone {
        float: left;
        opacity: 1;
        transition: all .35s;
        font: 300 16px/34px $default-font;
        color: #000;

        @media print {
            float: none;
            display: block !important;
            opacity: 1 !important;
        }

        a {
            color: inherit;

            &:hover {
                color: $red;
            }
        }
    }

    .header-logo {
        overflow: hidden;
        display: inline-block;
        width: 300px;
        transition: all .35s;
        z-index: 300;

        @media #{$md-min} {
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
        }

        @media #{$sm-max} {
            width: 245px;
        }

        img {
            max-width: inherit;

            @media #{$sm-max} {
                max-width: 100%;
            }
        }
    }

    .header-buttons {
        position: relative;
        float: right;
        background: #fff;
        transition: all .35s;
        z-index: 300;

        @media print {
            display: none !important;
        }

        a {
            display: block;
            width: 34px;
            height: 34px;
            float: left;
            padding: 4px;
            margin-left: 25px;
            transition: all .25s;
            text-align: right;
            font-size: 25px;
            line-height: 30px;
            color: $text-color;

            &:hover {
                color: $brand-color;
            }

            &.cart-link {
                position: relative;

                .cart-total {
                    position: absolute;
                    top: -2px;
                    left: 14px;
                    font: 900 12px/1 $default-font;
                    text-align: center;
                    color: $red;
                }
            }
        }
    }

    .header-navbar {
        position: relative;
        top: 0;
        width: 100%;
        height: 60px;
        //padding: 16px 0;
        transition: all .35s;
        z-index: 100;

        @media #{$sm-max} {
            padding: 15px 20px;
        }

        @media print {
            display: none;
        }
    }

    .mobile-panel {
        position: relative;
        //overflow: hidden;
        text-align: center;

        a {
            display: inline-block;
            width: 30px;
            height: 30px;
            padding: 1px;
            vertical-align: middle;
            margin-right: 15px;
            font-size: 25px;
            line-height: 30px;
            color: $text-color;
        }

        .menu-link {
            float: left;
            text-align: left;
            color: $brand-color;
        }

        .home-link {
            float: left;

            /*.logo-image {
                overflow: hidden;
                display: none;
                width: 60px;
                height: 30px;

                img {
                    width: auto;
                    height: 100%;
                }
            }*/
        }

        .cart-link {
            position: relative;
            float: right;

            .cart-total {
                position: absolute;
                top: -5px;
                right: 5px;
                font: 900 12px/1 $default-font;
                text-align: center;
                color: $red;
            }
        }

        .search-link {
            float: right;
        }

        .ordering-link {
            float: right;
            width: auto;
            background: $text-color;
            padding: 5px 10px;
            margin: 0;
            font: 400 12px/20px $default-font;
            text-transform: uppercase;
            color: #fff;
        }
    }

    .top-menu-panel {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid $border-color;
        background: #fff;
        padding: 20px 20px;
        transition: all .35s;

        .back-link {
            position: absolute;
            top: 20px;
            left: 20px;
            padding-left: 20px;
            transition: all .35s;
            visibility: hidden;
            opacity: 0;
            font-size: 14px;
            line-height: 20px;
            color: $text-color;

            &:before {
                content: '';
                position: absolute;
                top: 6px;
                left: 2px;
                display: block;
                width: 8px;
                height: 8px;
                border-left: 1px solid $text-color;
                border-bottom: 1px solid $text-color;
                transform: rotate(45deg);
            }
        }

        .phone-link {
            position: absolute;
            top: 20px;
            left: 20px;
            opacity: 1;
            visibility: visible;
            transition: all .35s;
            font: 400 16px/20px $secondary-font;
            color: $red;
        }

        .close-link {
            float: right;
            //font-size: 0;
            margin: -5px -5px 0 0;
            @extend %close-button;
        }
    }

    .ubermenu-active {
        .top-menu-panel {
            transform: translateX(-100%);
        }
    }

    .ubermenu-submenu-active {
        .back-link {
            visibility: visible;
            opacity: 1;
        }

        .phone-link {
            visibility: hidden;
            opacity: 0;
        }
    }

    .header-search {
        display: none;
    }
}