.win-icon {
    display: inline-block;
    vertical-align: middle;
    font: 400 26px/1 $segoe;
    color: $red;

    &.icon-info:before {
        content: '\e946';
    }

    &.icon-arrow-right:before {
        content: '\e0ab';
    }
}