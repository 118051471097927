.product-category-description {
    position: relative;
    overflow: hidden;
    margin: 0 0 40px;

    p {
        margin: 0 0 10px;

        &:empty {
            display: none;
        }

        img {
            float: left !important;
            max-width: 100%;
            margin: 15px 20px 20px 0 !important;

            @media #{$xs-max} {
                margin: 15px 20px 10px 0 !important;
            }

            @media #{$xm-max} {
                //width: 100% !important;
                height: auto !important;
                margin: 0 0 20px 0 !important;
            }
        }
    }

    p, span {
        font: 400 14px/22px $default-font !important;
        color: #414449 !important;
    }

    strong {
        font-family: $default-font !important;
        font-size: 14px !important;
    }

    a:not(.btn) {
        color: $brand-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.product-list {

    .product-item {
        padding: 30px 0;
        border-top: 1px solid $border-color;
        font-size: 0;

        &:last-child {
            border-bottom: 1px solid $border-color;
        }
    }

    .image-box,
    .text-box,
    .price-box {
        display: inline-block;
        vertical-align: top;
        padding: 0 20px;
        font: 400 14px/1.5 $default-font;
    }

    .image-box {
        width: 30%;

        @media #{$xs-max} {
            width: 100%;
        }

        .product-image {
            position: relative;
            display: block;
            min-width: 85px;
            text-align: center;
            line-height: 1px;
            transition: all .3s ease-out;

            @media #{$xm-max} {
                min-width: 50px;
            }

            &:hover {
                .quick-view {
                    visibility: visible;
                    opacity: 1;
                }
            }

            img {
                //width: auto;
                width: 250px;
                max-width: 100%;
                height: auto;
                max-height: 100%;
            }
        }

        .image-caption {
            margin-top: 10px;
            text-align: center;
            font-size: 12px;
        }
    }

    .text-box {
        width: 45%;
        padding: 40px 20px 20px;

        @media #{$xs-max} {
            width: 60%;
            padding-top: 30px;
        }

        @media #{$xm-max} {
            width: 100%;
            padding: 30px 20px 0;
        }

        .product-title {
            margin: 0 0 10px;
            font: 16px/1.5 $default-font;
            text-transform: uppercase;
        }

        .product-desc {
            line-height: 24px;
        }
    }

    .price-box {
        width: 25%;
        padding: 40px 20px 20px;

        @media #{$xs-max} {
            width: 40%;
            padding-top: 30px;
        }

        @media #{$xm-max} {
            width: 100%;
            padding: 30px 20px 0;
        }

        .price-table {
            margin: 0 auto;

            @media #{$xm-max} {
                width: 100%;
            }

            .price-row {
                font-weight: bold;
            }

            td {
                padding: 3px 2px;
                vertical-align: middle;

                &:last-child {
                    text-align: right;
                }
            }
        }

        label {
            margin: 0;
            font-size: 14px;
            font-weight: inherit;
            text-transform: uppercase;
        }
    }

    .new-product-label {
        margin: 0 0 5px;
        color: blue;
        font-style: italic;
        font-weight: bold;
    }

    .form-control {
        width: 90px;
        padding: 5px;
        text-align: center;
    }

    .btn {
        vertical-align: top;
        min-width: 150px;
        width: 100%;
    }

    a:not(.btn) {
        color: #000;

        &:hover {
            text-decoration: underline;
        }
    }
}

.content {

    .product-list {

        .image-box {

            @media #{$sm-only} {
                width: 60%;
                padding-top: 30px;
            }
        }

        .text-box {

            @media #{$sm-only} {
                width: 60%;
                padding-top: 30px;
            }
        }

        .price-box {

            @media #{$sm-only} {
                width: 40%;
                padding-top: 30px;
            }
        }
    }
}

//.product-info {
//
//    @media #{$xs-max} {
//        padding: 5px 0 5px 15px;
//        line-height: 20px;
//
//        .mobile-info {
//            div {
//                margin: 5px 0;
//            }
//        }
//    }
//
//    .product-number {
//        color: #ccc;
//    }
//
//    .button-holder {
//        overflow: hidden;
//        white-space: nowrap;
//
//        .form-control {
//            width: 70px;
//        }
//
//        .btn {
//            float: right;
//            width: 115px;
//            padding: 8px 10px;
//            font-size: 16px;
//        }
//    }
//}

.product-list-pagination {
    position: relative;
    overflow: hidden;
    font-size: 0;

    a {
        display: inline-block;
        width: 40px;
        height: 40px;
        padding: 5px;
        margin-right: 15px;
        border-radius: 50%;
        border: 1px solid $light-grey;
        transition: all .25s;
        font: 700 16px/28px $secondary-font;
        text-align: center;
        color: $text-color;

        &:hover,
        &.active {
            border-color: $text-color;
            //background: #f2f2f2;
        }
    }
}

.post-content {
    clear: both;
}