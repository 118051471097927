.sidebar {
    position: relative;
    margin: 0 0 20px;

    &.sticky {
        .navigation-box {
            position: fixed;
            top: 80px;

            .heading {
                display: none;
            }

            .toggle-panel {
                display: block;
            }

            .nav {
                display: none;
                padding: 10px;
                border-top: 1px solid $border-color;
            }
        }

        .order-box {
            position: fixed;
            top: 160px;
        }
    }

    .sidebar-box {
        max-width: 285px;
        margin: 0 0 20px;
        border: 1px solid $border-color;
        z-index: 20;

        .heading {
            position: relative;
            padding: 20px;
            background: #fff;

            .title {
                margin: 0;
            }
        }

        @media #{$xs-max} {
            border: none;
        }

        @media #{$xs-max} {
            max-width: 100%;
        }

        .btn-toggle {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            padding: 0;
            display: block;
            border: none;
            cursor: pointer;
            background: none;

            &:hover,
            &:active,
            &:focus {
                box-shadow: none;
            }

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                background: $text-color;
                transition: all .25s;
            }

            &:before {
                width: 15px;
                height: 1px;
                right: 15px;
                top: 50%;
                margin-top: -1px;
                transform: rotate(180deg);
            }

            &:after {
                width: 1px;
                height: 15px;
                right: 22px;
                top: 50%;
                margin-top: -8px;
                opacity: 1;
                transform: rotate(0deg);
            }

            &.on {
                &:before {
                    transform: rotate(0deg);
                }

                &:after {
                    transform: rotate(180deg);
                    opacity: 0;
                }
            }
        }
    }

    .navigation-box {
        width: 100%;
        margin: 0 0 20px;
        border: 1px solid $border-color;
        z-index: 20;

        @media #{$xs-max} {
            border: none;
        }

        .heading {
            position: relative;
            display: block;
            padding: 20px;

            @media #{$xs-max} {
                padding: 0;
                margin: 0 0 20px;
            }

            .title {
                margin: 0;
                line-height: 30px;
            }

            .btn {
                position: absolute;
                top: 50%;
                right: 17px;
                width: 16px;
                height: 16px;
                padding: 0;
                margin-top: -8px;
                border-radius: 50%;
                border: none;
                text-align: center;
                background: url(#{$path-img}i-ico.png) no-repeat 0 0;
                color: red;
                transition: none;
                @include square(15px);
                background-size: 15px 45px;

                @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                    background-image: url(#{$path-img}i-ico@2x.png);
                }

                &:hover {
                    box-shadow: none;
                    background-position: 0 -15px;
                }


                &.active {
                    box-shadow: none;
                    background-position: 0 -30px;
                }
            }
        }

        .toggle-panel {
            position: relative;
            display: none;
            padding: 20px;
            background: url('#{$path-img}bg-pattern-grey-2.png');

            @media #{$xs-max} {
                display: block;
                border: 1px solid $border-color;
            }

            .title {
                margin: 0;
            }

            .btn {
                background: transparent;
            }
        }

        .nav {
            padding: 10px;
            background: #fff;

            @media #{$xs-max} {
                display: none;
                padding: 10px;
                border: 1px solid $border-color;
                border-top: 0;
            }

            li {
                padding: 0;

                &.active {
                    a {
                        background: url('#{$path-img}bg-pattern-grey-2.png');
                    }
                }

                &.edited {
                    a:before {
                        content: '';
                        color: #d6d9de;
                    }
                }

                &.added {
                    a:before {
                        content: '';
                        color: $dark-grey;
                    }
                }

                &.disabled {

                    a {
                        cursor: not-allowed;
                        background-color: transparent;
                    }
                }
            }

            a {
                position: relative;
                display: block;
                padding: 10px 20px 10px 40px;
                background-color: #fff;
                transition: all .25s;
                color: $text-color;

                &:after, &:before {
                    position: absolute;
                    top: 50%;
                }

                &:after {
                    content: '';
                    right: 10px;
                    margin-top: -6px;
                    font: 400 12px/1 $segoe;
                }

                &:not(.info):before {
                    content: '';
                    left: 10px;
                    margin-top: -8px;
                    font: 400 16px/1 $segoe;
                    color: #d6d9de;
                }
            }

            a:hover {
                background-color: #f6f6f6;
            }
        }
    }

    .order-box {
        width: 100%;
        margin: 0 0 20px;
        border: 1px solid $border-color;
        z-index: 10;

        .heading {
            position: relative;
            padding: 20px;
            background: #fff;

            .title {
                margin: 0;
            }
        }

        .btn-toggle,
        .btn-toggle:active,
        .btn-toggle:focus,
        .btn-toggle:active:focus {
            background: none;
        }

        .order-table {
            padding: 10px 0 20px 10px;
            border-top: 1px solid $border-color;
            background: #f2f2f2;
        }

        .table {
            width: 100%;
            margin: 0;
            color: $text-color;

            td,
            th {
                padding: 8px;
                font-size: 12px;
                line-height: 22px;
                border-bottom: 1px solid $border-color;
                position: relative;

                &:empty {
                    padding: 0;
                }
            }

            .cell-remove {
                background-color: #f2f2f2;
                vertical-align: middle;
                border: none;
                padding: 2px;
                width: 30px;
                text-align: center;
            }

            thead {
                th {
                    padding-top: 0;
                    text-align: right;

                    &:first-child {
                        text-align: left;
                    }
                }
            }

            tbody + tbody {
                //border: none;
            }

            tfoot {
                td {
                    border: none;
                    font-weight: 700;
                }
            }
        }

        .button-holder {
            text-align: center;

            p {
                margin: 0 0 10px;

                &:first-child {
                    margin-top: 10px;
                }
            }

            .btn {
                min-width: 185px;
                max-width: 100%;
            }

            .dropdown {
                display: inline-block;
                vertical-align: top;
            }

            .warning {
                color: $red;
            }
        }
    }

    .bdr {
        display: block;
        height: 16px;
    }

    .ico-remove {
        font-size: 16px;
        line-height: 1;
        vertical-align: middle;
        color: red;
        display: inline-block;

        &:hover {
            color: #950508;
        }
    }

    .link-remove {
        position: absolute;
        right: 8px;
        bottom: 6px;
        font-weight: 700;
        text-decoration: underline;
        font-size: 11px;
        color: red;

        &:hover {
            text-decoration: none;
            color: #950508;
        }
    }
}

.side-box {
    margin: 0 0 20px;
    border: 1px solid #d6d9de;
    z-index: 20;

    @media #{$sm-min} {
        max-width: 285px;
    }

    .heading {
        position: relative;
        padding: 20px;
        background: #fff;

        .title {
            margin: 0;
        }
    }
}

.side-nav {
    margin: 0;
    padding: 10px 20px;
    list-style: none;
    border-top: 1px solid $border-color;

    > li {

        &.active {

            > a {
                color: $brand-color;
            }
        }

        > a {
            color: inherit;

            &:hover {
                color: $brand-color;
            }
        }
    }
}
