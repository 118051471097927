body.frame-calculator {
    min-width: 260px;
    overflow: auto;
    background: transparent;
    font: 400 14px/22px "Open Sans", sans-serif;
    color: #414449;
}

.help-container {
    .help-title {
        display: block;
        padding: 10px;
        margin: 0 0 10px;
        background: #f2f2f2;
        text-decoration: none;
        font-size: 16px;
        color: #414449;

        &:active,
        &:focus,
        &:hover {
            background: #eee;
            text-decoration: none;
            color: #414449;
        }
    }

    .help-block {
        display: none;
        padding: 20px;
        background: #f2f2f2;
        color: #414449;

        img {
            float: right;
            margin: 0 0 0 10px;
        }
    }
}

#fdc-form {
    table {
        width: 100%;

        th {
            padding: 2px 10px;
            background: #f2f2f2;
            border-bottom: 1px solid #fff;
            width: 150px;
        }

        td {
            background: #fff;
            border-bottom: 1px solid #f2f2f2;
            padding: 5px;
        }
    }

    legend {
        margin-bottom: 0;
        padding: 10px;
        border-bottom: 1px solid #f2f2f2;
        font-size: 20px;
    }

    .form-group {
        max-width: 900px;
    }

    .checkbox-control {
        width: 175px;
        margin-right: 20px;

        label {
            margin: 3px 0;
            font-size: 14px;
        }
    }

    select.form-control {
        width: 180px;
    }

    #tdc-result {
        font-size: 18px;

        .total-depth {
            display: inline-block;
            min-width: 75px;
        }

        .btn {
            margin-right: 20px;
            padding: 8px 20px;
            font-size: 15px;

            @media #{$xm-max} {
                width: 180px;
                margin: 5px 0 0;
            }
        }
    }

    #profile_depth,
    #recess_amount {
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
    }
}