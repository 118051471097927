.cart-page-content {
    .order-table {
        border-collapse: separate;

        @media #{$xs-max} {
            font-size: 12px;
        }

        @media #{$xm-max} {
            font-size: 11px;
        }

        thead {
            @media #{$xm-max} {
                font-size: 12px;
            }

            tr {
                background: #eee;

                td {
                    &:last-child {
                        border-right: 1px solid #eee;
                    }
                }
            }
        }

        tbody {

            tr {
                transition: all .25s;
                background: transparent;

                &:nth-child(even) {
                    background: #f5f5f5;
                }

                &:hover {
                    background: #eee;
                }

                td {

                    &:first-child {
                        border: none;
                        border-right: 1px solid $dark-grey;
                        background: #eee;
                    }

                    &:last-child {
                        border-right: 1px solid $dark-grey;
                    }
                }
            }
        }

        tfoot {
            font-size: 14px;

            tr {
                background: #eee;

                td {
                    &:first-child {
                        border: none;
                    }

                    &:last-child {
                        border-right: 1px solid #eee;
                    }
                }
            }
        }

        td.total {
            position: relative;
            width: 180px;
            text-align: right;

            @media #{$xm-max} {
                padding: 5px 5px 50px;
            }
        }

        .actions {
            float: left;
            display: inline-block;
            width: 115px;
            border-radius: 5px;
            font-size: 15px;
            text-align: center;
            color: $dark-grey;

            a {
                text-align: center;
                color: inherit;

                &:hover {
                    color: $red;
                }
            }

            @media #{$xm-max} {
                float: none;
                position: absolute;
                right: 5px;
                bottom: 5px;
                text-align: right;
            }
        }

        input {
            height: 21px;
            text-align: center;
            border: 1px solid $dark-grey;
        }
    }

    .button-holder {
        margin: 20px 0;

        .btn {
            @media #{$xs-max} {
                padding: 8px 10px;
                font-size: 16px;
            }
        }
    }

    .bottom-text {
        @media #{$xs-max} {
            font-size: 13px;
        }
    }
}