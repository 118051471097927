.profile-listing {
    position: relative;
    overflow: hidden;
    padding: 0 0 1px 1px;

    .profile-box {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        height: auto;
        background: #fff;
        text-align: center;
        z-index: 10;

        &.info-visible {
            z-index: 20;

            .profile-info {
                visibility: visible;
                opacity: 1;

                @media #{$sm-max} {
                    position: relative;
                }
            }

            .inner:after {
                opacity: 1;
            }
        }

        &.type-metal {
            .profile-info {
                background: #555;
            }
        }

        @media #{$md-min} {
            &:nth-child(4n+1) {
                &.info-visible {
                    .inner {
                        left: 0;
                    }
                }
            }

            &:nth-child(4n+2) {
                &.info-visible {
                    .inner {
                        left: -100%;
                    }
                }
            }

            &:nth-child(4n+3) {
                &.info-visible {
                    .inner {
                        left: -200%;
                    }
                }
            }

            &:nth-child(4n) {
                &.info-visible {
                    .inner {
                        left: -300%;
                    }
                }
            }
        }

        @media #{$sm-max} {
            width: 33.3333%;

            &:nth-child(3n+1) {
                .profile-info {
                    left: 0;
                }
            }

            &:nth-child(3n+2) {
                .profile-info {
                    left: -100%;
                }
            }

            &:nth-child(3n) {
                .profile-info {
                    left: -200%;
                }
            }
        }

        @media #{$xs-max} {
            width: 50%;

            &:nth-child(2n+1) {
                .profile-info {
                    left: 0;
                }
            }

            &:nth-child(2n) {
                .profile-info {
                    left: -100%;
                }
            }
        }

        .inner {
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
            height: auto;
            min-height: 250px;
            background: #fff;
            transition: all .35s;

            &:before {
                content: '';
                position: absolute;
                left: -1px;
                top: 0;
                right: 0;
                bottom: -1px;
                pointer-events: none;
                border: 1px solid $border-color;
            }

            &:after {
                content: '';
                position: absolute;
                top: 58px;
                right: -10px;
                display: block;
                width: 20px;
                height: 20px;
                background: #fff;
                opacity: 0;
                transition: all .35s;
                transform: rotate(45deg);
                z-index: 90;

                @media #{$sm-max} {
                    top: 240px;
                    right: auto;
                    left: 50%;
                    margin-left: -10px;
                }
            }
        }

        .profile-card {
            display: block;
            min-height: 250px;
            padding: 1px;

            .image {
                display: table;
                width: 100%;

                .table-cell {
                    display: table-cell;
                    width: 100%;
                    height: 200px;
                    padding: 20px 20px 0;
                    vertical-align: middle;
                    text-align: center;
                }

                a {
                    display: inline-block;
                }

                img {
                    height: auto;
                    max-height: 160px;
                }
            }

            .text {
                height: 48px;
                color: $text-color;
            }

            .info-toggle {
                padding: 5px;
                cursor: pointer;
            }

            .star_container {
                height: 20px;
                margin-bottom: 10px;

                .stars {
                    margin-right: 5px;
                }
            }
        }

        .profile-info {
            overflow: hidden;
            visibility: hidden;
            opacity: 0;
            width: 300%;
            height: 251px;
            min-height: 251px;
            position: absolute;
            left: 100%;
            top: 0;
            border: 1px solid $border-color;
            background: #666;
            transition: all .35s;
            z-index: 30;

            @media #{$sm-min} {
                border-left: none;
            }

            @media #{$sm-max} {
                top: 100%;
                left: 0;
                height: auto;
            }

            @media #{$xs-max} {
                width: 200%;
                height: auto;
                left: 0;
                background: #555; // to fit mobile info images
            }

            .text {
                position: relative;
                float: left;
                width: 33.3333%;
                height: 250px;
                min-height: 250px;
                padding: 30px;

                @media #{$xs-max} {
                    width: 50%;
                    height: auto;
                }

                @media #{$xm-max} {
                    width: 100%;
                    min-height: 0;
                }

                .title {
                    margin: 0;
                    padding-top: 20px;
                    font-size: 24px;
                    line-height: 1.5;
                    color: #fff;

                    @media #{$xs-max} {
                        padding: 0;
                    }
                }

                .btn {
                    width: 150px;
                    position: absolute;
                    bottom: 50px;
                    left: 50%;
                    margin-left: -75px;

                    @media #{$xm-max} {
                        display: none;
                    }
                }
            }

            .image {
                float: left;
                display: table;
                width: 66.6666%;
                height: 100%;
                min-height: 250px;

                @media #{$xs-max} {
                    width: 50%;
                    height: auto;
                    text-align: center;
                }

                @media #{$xm-max} {
                    width: 100%;
                }

                .table-cell {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 0 20px;

                    @media #{$sm-max} {
                        padding: 20px;
                    }
                }

                img {
                    width: auto;
                    max-height: 250px;

                    @media #{$sm-max} {
                        max-height: 100%;
                    }

                    &.visible-desktop {
                        display: inline-block;

                        @media #{$xs-max} {
                            display: none;
                        }
                    }

                    &.visible-mobile {
                        display: none;

                        @media #{$xs-max} {
                            display: inline-block;
                        }
                    }
                }
            }

            .button-holder {
                display: none;
                overflow: hidden;
                padding: 30px;
                text-align: center;

                @media #{$xm-max} {
                    display: block;
                }
            }

            .close {
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 30px;
                color: #fff;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.profile-search-panel {
    position: relative;
    overflow: hidden;
    margin: 0 0 30px;

    .title {
        float: left;
        margin: 0;
        padding: 5px 0;
    }

    .btn {
        float: right;
    }
}