* {
    outline: none;
}

html, body {
    min-height: 100%;
}

body {
    position: relative;
    background-color: $white;
    min-width: 320px;
}

.layout {
    overflow: hidden;
    min-height: 100%;
    margin: 0 auto;
    padding-top: 135px;
    background: #fff;
    transition: all .35s;

    @media #{$sm-max} {
        padding-top: 120px;
    }

    @media print {
        padding-top: 0 !important;
    }

    &.search-active {
        padding-top: 235px;
    }

    &.on-scrolling.search-active {
        padding-top: 150px;

        @media #{$sm-max} {
            padding-top: 120px;
        }
    }

    &.blured {
        filter: blur(5px);
    }
}

.container {
    width: auto;
    max-width: 1280px;
    padding: 0 20px;
    transition: all .35s;
}

%heading {
    font-size: 1.25em;
    line-height: 1;
    font-weight: 900;
    margin: 0 0 1em;
    text-align: center;
}

%bg-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

// hide wp admin panel
body.customize-support {
    .layout {
        header {
            top: 32px;

            @media #{$sm-max} {
                top: 46px;
            }
        }
    }
}

.table-responsive {
    border: none;

    @media screen and (max-width: 450px) {
        padding: 10px;
        border: 1px solid #eee;
    }
}

.clickable {
    cursor: pointer;
}