body {
    font: 400 14px/22px $text-font;
    color: $text-color;
}

a {
    outline: none;
    transition: color .25s;
    text-decoration: none;
    font-size: inherit;
    color: $brand-color;

    &:hover, &:focus {
        outline: none;
        text-decoration: none;
        color: $brand-color;
    }
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    padding: 0;
    margin: 0 0 25px;

    a {
        color: inherit;
        font-family: inherit;
    }
}

h1, .h1,
h2, .h2 {
    position: relative;
    margin: 0 0 20px;
    text-transform: uppercase;
    color: $text-color;
}

h1, .h1 {
    font: 400 30px/36px $novecento, sans-serif;

    @media #{$xs-max} {
        font-size: 26px;
        line-height: 34px;
    }
}

h2, .h2 {
    font: 400 24px/32px $novecento, sans-serif;
}

h3, .h3 {
    margin: 0 0 20px;
    font: 400 24px/32px $novecento, sans-serif;

    @media #{$xs-max} {
        font-size: 20px;
        line-height: 28px;
    }
}

h4, .h4 {
    margin: 10px 0 20px;
}

p {
    margin: 0 0 25px;

    &:last-child {
        margin: 0;
    }
}

ul, ol {
    @extend %clear-list;
}

img,
iframe {
    border: none;
    max-width: 100%;
}

.nowrap {
    overflow: hidden;
    white-space: nowrap;
}